export class PhanQuyen {
    id: string;
    idSite: string;
    idNhomNguoiDung: string;
    idNhomQuyen: string;
    taoBoi: string;
    taoLuc: Date;
    capNhatBoi: string;
    capNhatLuc: Date;
    constructor(obj: any) {
        obj = obj || {};
        this.id = obj.id;
        this.idSite = obj.idSite;
        this.idNhomNguoiDung = obj.idNhomNguoiDung;
        this.idNhomQuyen = obj.idNhomQuyen;
        this.taoBoi = obj.taoBoi;
        this.taoLuc = obj.taoLuc;
        this.capNhatBoi = obj.capNhatBoi;
        this.capNhatLuc = obj.capNhatLuc;
    }
}
