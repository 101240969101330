import { Injectable } from "@angular/core";
import { Api } from "src/app/core/network/api";
import { ApiUrl } from "src/app/core/enums/api_key";
import { iMeBaseService } from "src/app/core/services/is-mebase.service";
import { UrlService } from "src/app/core/network/url.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
export class UploadFileService<T> extends iMeBaseService<T>{
    public static instance: UploadFileService<any>;

    constructor(private api: Api<T>,private urlService: UrlService) {
        super(api);
        if (!UploadFileService.instance) {
            UploadFileService.instance = this;
        }
  
        return UploadFileService.instance;
    }

    uploadFile(formData: FormData) {
        return this.api.postMultiPartJSON(`${this.urlService.getBaseUrl()}${ApiUrl.UPLOAD_FILE}/upload`, formData);
    }

    getDetail(id: string) {
        return this.api.get(`${this.urlService.getBaseUrl()}${ApiUrl.UPLOAD_FILE}/chi-tiet/${id}`);
    }   
    
    getList(page: number, pageSize: number,searchStr: string='',searchKey: string='') {
        let _map = `?pageNumber=${page}&pageSize=${pageSize}`;
        if(searchStr != ''){
            if(searchKey!=''){
            _map+=`&Search.${searchKey}=${searchStr}`;
            }else{
                //default search by Ten
                _map+=`&Search.Ten=${searchStr}`;
            }
        }
        return this.api.get(`${this.urlService.getBaseUrl()}${ApiUrl.UPLOAD_FILE}/danh-sach${_map}`);
    }

    //remove
    remove(id: string) {
        return this.api.delete(`${this.urlService.getBaseUrl()}${ApiUrl.UPLOAD_FILE}/xoa/${id}`);
    }

    getImgPath(detailImg: any){
        return `${environment.apiUrl}/files-thumbnail/${detailImg.idSite}/${detailImg.idNguoiDung}/${detailImg.ten}`;
      }
}