export enum ApiUrl {
    //todo: dùng cho chức năng đăng nhập mức admin 
    API_MANAGER= '/api/quan-ly/',
    //todo: dùng cho chức năng đăng nhập mức client
    API_CLIENT ='/api/',
    HOME = '/',
    LOGIN = '/token',
    REFRESH_TOKEN = ' ',
    // api quản lý giấy tờ
    DS_LOAI_GIAY_TO = '/api/quan-ly/loai-giay-to/danh-sach',
    TAO_LOAI_GIAY_TO = '/api/quan-ly/loai-giay-to/them-moi',
    CAP_NHAT_LOAI_GIAY_TO = '/api/quan-ly/loai-giay-to/cap-nhat',
    XOA_LOAI_GIAY_TO = '/api/quan-ly/loai-giay-to/xoa',
    CHITIET_LOAI_GIAY_TO = '/api/quan-ly/loai-giay-to/chi-tiet',
    DS_LOAI_TO_CHUC = "/api/quan-ly/loai-to-chuc/danh-sach",
    TAO_LOAI_TO_CHUC = "/api/quan-ly/loai-to-chuc/them-moi",
    CAP_NHAT_LOAI_TO_CHUC = "/api/quan-ly/loai-to-chuc/cap-nhat",
    XOA_LOAI_TO_CHUC = "/api/quan-ly/loai-to-chuc/xoa",
    CHITIET_LOAI_TO_CHUC = "/api/quan-ly/loai-to-chuc/chi-tiet",
    DS_Site = "/api/quan-ly/Site/danh-sach",
    NGUOI_DUNG = 'nguoi-dung',
    LOAI_TO_CHUC = 'loai-to-chuc',
    ERROR_CODE = 'ma-loi',
    LANGUAGE = 'ngon-ngu',
    EMAIL_TEMPLATE = 'mau-email',
    ORGANIZATION = 'to-chuc',
    SMS_TEMPLATE = 'mau-sms',
    ACCOUNT = 'nguoi-dung',
    CONFIGURATION = 'cau-hinh',
    CHUC_NANG = 'chuc-nang',
    OTP_TYPE = 'loai-otp',
    OTP = 'otp',
    PHAN_QUYEN = 'phan-quyen',
    PHAN_HE = 'phan-he',
    NEN_TANG = 'nen-tang',
    PHAN_QUYEN_CHUC_NANG = 'nhom-quyen-chuc-nang',
    NHOM_QUYEN = 'nhom-quyen',
    SITE_CLIENT = 'site-client',
    SITE = 'site',
    NHOM_NGUOI_DUNG = 'nhom-nguoi-dung',
    NguoiDungDangNhap = '',
    LOAI_GIAY_TO = 'loai-giay-to',
    GOOGLE_LOGIN = '/login-with-google',
    FACEBOOK_LOGIN = '/login-with-facebook',
    UPLOAD_FILE = 'file-upload',
    // api quản lý người dùng
}