export const environment = {
  production: false,
  serviceWorker: false,
  apiUrl: 'https://api.lifo.vn',
  clientId: '9D32FAD4-16D2-43CB-B4AF-B422ECD27A9D',
  clientSecret: '1C8726B5-7632-4997-BCBD-568A6C421E2B',
  fcmToken: 'FCMToken',
  appRole: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  firebaseConfig : {
    apiKey: "AIzaSyAH5h9UcqfvYS8kFf_hofkWGx4nFHkJUow",
    authDomain: "isme-bb2e8.firebaseapp.com",
    projectId: "isme-bb2e8",
    storageBucket: "isme-bb2e8.appspot.com",
    messagingSenderId: "287139946413",
    appId: "1:287139946413:web:3cc4322ff298925bd5cb24"
  }
};