export class Configration {
    id: string;
    idSite: string;
    ma: string;
    ten: string;
    giaTri: string;
    moTa: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(config: any) {
        config = config || {};
        this.id = config.id;
        this.idSite = config.idSite;
        this.ma = config.ma;
        this.giaTri = config.giaTri;
        this.ten = config.ten;
        this.moTa = config.moTa;
        this.taoBoi = config.taoBoi;
        this.taoLuc = config.taoLuc;
        this.capNhatBoi = config.capNhatBoi;
        this.capNhatLuc = config.capNhatLuc;
    }
}
