import { Site } from "../../manager/models/site.model"

export class Account {
        id: string
        idSite: string
        tenTaiKhoan: string
        hoTen: string
        hinhDaiDien: any
        email: string
        daXacThucEmail: boolean
        thoiGianXacThucEmail: string
        soDienThoai: string
        daXacThucSoDienThoai: boolean
        thoiGianXacThucSoDienThoai: string
        diaChi: string
        appleId: any
        googleId: any
        facebookId: any
        thoiGianKhoaTruyCap: any
        taoBoi: any
        taoLuc: any
        capNhatBoi: any
        capNhatLuc: any
        site: Site;
      constructor(data: any) {
        this.id = data?.id;
        this.idSite = data?.idSite;
        this.tenTaiKhoan = data?.tenTaiKhoan;
        this.hoTen = data?.hoTen;
        this.hinhDaiDien = data?.hinhDaiDien;
        this.email = data?.email;
        this.daXacThucEmail = data?.daXacThucEmail;
        this.thoiGianXacThucEmail = data?.thoiGianXacThucEmail;
        this.soDienThoai = data?.soDienThoai;
        this.daXacThucSoDienThoai = data?.daXacThucSoDienThoai;
        this.thoiGianXacThucSoDienThoai = data?.thoiGianXacThucSoDienThoai;
        this.diaChi = data?.diaChi;
        this.appleId = data?.appleId;
        this.googleId = data?.googleId;
        this.facebookId = data?.facebookId;
        this.thoiGianKhoaTruyCap = data?.thoiGianKhoaTruyCap;
        this.taoBoi = data?.taoBoi;
        this.taoLuc = data?.taoLuc;
        this.capNhatBoi = data?.capNhatBoi;
        this.capNhatLuc = data?.capNhatLuc;
        this.site = data?.site
    }
}
