export class RoleFunction {
    id: string;
    idSite: string;
    idNhomQuyen: string;
    idChucNang: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(rolef: any) {
        rolef = rolef || {};
        this.id = rolef.id;
        this.idSite = rolef.idSite;
        this.idNhomQuyen = rolef.idNguoiDung;
        this.idChucNang = rolef.idNenTang;
        this.taoBoi = rolef.taoBoi;
        this.taoLuc = rolef.taoLuc;
        this.capNhatBoi = rolef.capNhatBoi;
        this.capNhatLuc = rolef.capNhatLuc;
    }
}
