import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Analytics',
            route: '/',
            roleName: ['guest'],
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Cấu hình',
        children: [
          {
            type: 'link',
            label: 'Cài đặt giao diện',
            route: '/config/app-setting',
            icon: 'mat:assignment',
            roleName: ['guest'],
          },
          {
            type: 'link',
            label: 'Cấu hình hệ thống',
            route: '/config/app-config',
            icon: 'mat:assignment',
            roleName: ['guest'],
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Tài khoản & Người dùng',
        children: [
          {
            type: 'link',
            label: 'Người dùng',
            route: '/user-and-group/user',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_NguoiDung_LS'],
          },
          {
            type: 'link',
            label: 'Nhóm người dùng',
            route: '/user-and-group/user-group',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_NhomNguoiDung_LS'],
          },
          {
            type: 'link',
            label: 'Tài khoản',
            route: '/user-and-group/user-login',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_TaiKhoan_LS'],
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Phân quyền',
        children: [
          {
            type: 'link',
            label: 'Phân quyền',
            route: '/roles/roles',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_NhomQuyen_LS'],
          },
          {
            type: 'link',
            label: 'Nhóm quyền',
            route: '/roles/role-group',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_NhomQuyenChucNang_LS'],
          },
        ]
      },
       {
        type: 'subheading',
        label: 'Quản lý',
        children: [
          {
            type: 'link',
            label: 'Trang web',
            route: '/manager/site',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_Site_LS'],
          },
          {
            type: 'link',
            label: 'Thiết bị đầu cuối',
            route: '/manager/site-client',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_SiteClient_LS'],
          },
          {
            type: 'link',
            label: 'Chức năng',
            route: '/manager/app-function',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_NhomQuyen_LS'],
          },
          {
            type: 'link',
            label: 'Email mẫu',
            route: '/manager/email-template',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_MauEmail_LS'],
          }, {
            type: 'link',
            label: 'Mã lỗi',
            route: '/manager/error-code',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_MaLoi_LS'],
          },
          {
            type: 'link',
            label: 'Loại giấy tờ',
            route: '/manager/identification-type',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_LoaiGiayTo_LS'],
          },
          {
            type: 'link',
            label: 'Ngôn ngữ',
            route: '/manager/languages',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_NgonNgu_LS'],
          },
          {
            type: 'link',
            label: 'Loại tổ chức',
            route: '/manager/organization-type',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_LoaiToChuc_LS'],
          },
          {
            type: 'link',
            label: 'Tổ chức',
            route: '/manager/organization',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_ToChuc_LS'],
          },
          {
            type: 'link',
            label: 'Loại otp',
            route: '/manager/otp-type',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_Otp_LS'],
          },
          {
            type: 'link',
            label: 'Nền tảng',
            route: '/manager/platform',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_NenTang_LS'],
          },
          {
            type: 'link',
            label: 'SMS mẫu',
            route: '/manager/sms-template',
            icon: 'mat:assignment',
            roleName: ['ISME_HT_MauSMS_LS'],
          },
          {
            type: 'link',
            label: 'Phân hệ',
            route: '/manager/phan-he',
            icon: 'mat:assignment',
            roleName: ['SME_HT_PhanHe_LS'],
          },
          {
            type: 'link',
            label: 'Thông báo',
            route: '/manager/notification',
            icon: 'mat:assignment',
            roleName: ['SME_HT_ThongBao_LS'],
          },
        ]
      }
    ]);
  }
}
