export class EmailTemplate {
    id: string;
    idSite: string;
    ten: string;
    moTa: string;
    noiDung: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(emailTemplate: any) {
        emailTemplate = emailTemplate || {};
        this.id = emailTemplate.id;
        this.idSite = emailTemplate.idSite;
        this.noiDung = emailTemplate.noiDung;
        this.ten = emailTemplate.ten;
        this.moTa = emailTemplate.moTa;
        this.taoBoi = emailTemplate.taoBoi;
        this.taoLuc = emailTemplate.taoLuc;
        this.capNhatBoi = emailTemplate.capNhatBoi;
        this.capNhatLuc = emailTemplate.capNhatLuc;
    }
}
