export class Otp {
    id: string;
    idSite: string;
    idLoaiOtp: string;
    idNguoiDungDangNhap: string;
    maOtp: string;
    thoiGianTao: Date;
    soLanNhapSai: number;
    thoiGianNhapSaiCuoi: Date;
    thoiGianXacThucDung: Date;
    constructor(otp: any) {
        otp = otp || {};
        this.id = otp.id;
        this.idSite = otp.idSite;
        this.idLoaiOtp = otp.idLoaiOtp;
        this.idNguoiDungDangNhap = otp.idNguoiDungDangNhap;
        this.maOtp = otp.maOtp;
        this.thoiGianTao = otp.thoiGianTao;
        this.soLanNhapSai = otp.soLanNhapSai;
        this.thoiGianNhapSaiCuoi = otp.thoiGianNhapSaiCuoi;
        this.thoiGianXacThucDung = otp.thoiGianXacThucDung
        
    }
}
