import { Site } from "./site.model";

export class RoleGroup {
    id: string;
    idSite: string;
    ma: string;
    ten: string;
    moTa: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    siteUrl: string;
    site: Site;
    constructor(roleGroup: any) {
        roleGroup = roleGroup || {};
        this.id = roleGroup.id;
        this.idSite = roleGroup.idSite;
        this.ma = roleGroup.ma;
        this.ten = roleGroup.ten;
        this.moTa = roleGroup.moTa;
        this.taoBoi = roleGroup.taoBoi;
        this.taoLuc = roleGroup.taoLuc;
        this.capNhatBoi = roleGroup.capNhatBoi;
        this.capNhatLuc = roleGroup.capNhatLuc;
        this.site = roleGroup.site;
        this.siteUrl = this.site?.siteUrl;
    }
}
