export class OtpType {
    id: string;
    ten: string;
    moTa: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(otpType: any) {
        otpType = otpType || {};
        this.id = otpType.id;
        this.ten = otpType.ten;
        this.moTa = otpType.moTa;
        this.taoBoi = otpType.taoBoi;
        this.taoLuc = otpType.taoLuc;
        this.capNhatBoi = otpType.capNhatBoi;
        this.capNhatLuc = otpType.capNhatLuc;
    }
}
