export class IdentificationType {
    id: number;
    ten: string;
    moTa: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(identificationType: any) {
        identificationType = identificationType || {};
        this.id = identificationType.id;
        this.ten = identificationType.ten;
        this.moTa = identificationType.moTa;
        this.taoBoi = identificationType.taoBoi;
        this.taoLuc = identificationType.taoLuc;
        this.capNhatBoi = identificationType.capNhatBoi;
        this.capNhatLuc = identificationType.capNhatLuc;
    }
}
