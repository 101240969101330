import { Site } from "../../manager/models/site.model";

export class UserGroup {
    id: string;
    idSite: string;
    ma: string;
    ten: string;
    moTa: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    siteUrl: string;
    site: Site;
    constructor(group: any) {
        group = group || {};
        this.id = group.id;
        this.idSite = group.idSite;
        this.ma = group.ma;
        this.ten = group.ten;
        this.moTa = group.moTa;
        this.taoBoi = group.taoBoi;
        this.taoLuc = group.taoLuc;
        this.capNhatBoi = group.capNhatBoi;
        this.capNhatLuc = group.capNhatLuc;
        this.site = group.site;
        this.siteUrl = this.site?.siteUrl;
    }
}
