export class UserLogin {
    id: string;
    idSite: string;
    idNguoiDung: string;
    idNenTang: string;
    fcmToken: string;
    thoiGianDangNhap: string;
    thoiGianDangXuat: string;
    constructor(userLogin: any) {
        userLogin = userLogin || {};
        this.id = userLogin.id;
        this.idSite = userLogin.idSite;
        this.idNguoiDung = userLogin.idNguoiDung;
        this.idNenTang = userLogin.idNenTang;
        this.fcmToken = userLogin.fcmToken;
        this.thoiGianDangNhap = userLogin.thoiGianDangNhap;
        this.thoiGianDangXuat = userLogin.thoiGianDangXuat;
    }
}
