import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Api } from "src/app/core/network/api";
import { ApiUrl } from "src/app/core/enums/api_key";
import { environment as env } from "src/environments/environment";
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { LocalStorageService } from "src/app/core/services/localstore.service";
import { LocalStoreKey } from "src/app/core/enums/localstore_key";
import { getDataToken } from "src/app/core/commons/storage";
const default_user = '00000000-0000-0000-0000-000000000000'
@Injectable({
  providedIn: 'root'
})
export class AuthService<T> {
    public static instance: AuthService<any>;

    constructor(
      private api: Api<T>,
      private storageService: LocalStorageService,
      private router: Router,
    ){
        if(!AuthService.instance){
            AuthService.instance = this;
        }
        return AuthService.instance;
    }

    /**
     * API Login
     * @param data
     */
    appLogin(): Observable<any> {
        const data = {
            clientId: env.clientId,
            clientSecret: env.clientSecret,
        }
        return this.api.post(ApiUrl.LOGIN, data).pipe(
            tap((response: any) => {
                if (response.isSuccess) {
                  const data = response.data;
                    this.storageService.setItem(LocalStoreKey.appToken,data.accessToken);
                    this.storageService.setItem(LocalStoreKey.refreshToken,data.refreshToken)
                    this.storageService.setItem(LocalStoreKey.expiredIn,data.expiration);
                    const dataToken: any = getDataToken();
                    this.storageService.setItem(LocalStoreKey.idNguoiDung,dataToken.IdNguoiDung)
                }
            })
        );
    }

    /**
     * API Login
     * @param data
     */
    login(username:string, password: string) {
        const data = {
            clientId: env.clientId,
            clientSecret: env.clientSecret,
            email: username,
            matKhau: password,
            fcmToken: env.fcmToken
        }
        return this.api.post(ApiUrl.LOGIN, data);
    }

    getAccountInfo() {
        return this.api.post(`/api/quan-ly/nguoi-dung/thong-tin`,null);
    }

    googleLogin(token: string,fcmToken: string) {
        const data = {
            clientId: env.clientId,
            clientSecret: env.clientSecret,
            fcmToken: fcmToken,
            accessToken: token
        }
            return this.api.post(ApiUrl.GOOGLE_LOGIN, data);
    }

    facebookLogin(token: string,fcmToken: string) {
        const data = {
            clientId: env.clientId,
            clientSecret: env.clientSecret,
            fcmToken: fcmToken,
            accessToken: token
        }
        return this.api.post(ApiUrl.FACEBOOK_LOGIN, data);
    }
    
    register(data: any){
        const url =`${ApiUrl.API_MANAGER}${ApiUrl.NGUOI_DUNG}/them-moi`;
        return this.api.post(url,data);
    }

    requestForgotPassword(data: any){
        const url =`/api/site/quen-mat-khau-nguoi-dung`;
        return this.api.post(url,data);
    }

    confirmResetPassword(data: any){
        const url =`/api/site/xac-nhan-quen-mat-khau-nguoi-dung`;
        return this.api.post(url,data);
    } 
  
    getCurrentUser() {
        const url =`/thong-tin-nguoi-dung`;
        return this.api.get(url);
    }
    
    updateUserProfile(data: any) {
        const url =`/cap-nhat-thong-tin-nguoi-dung`;
        return this.api.post(url,data);
    }

    checkAndAutoLogin(): Observable<boolean> {
        if (this.storageService.hasToken) {
            return of(true);
        } else {
            return this.appLogin().pipe(
                map((response: any) => {
                    if (response.isSuccess) {
                        console.log('Auto login successful');
                        return true;
                    }
                    return false;
                }),
                catchError((error) => {
                    console.error('Auto login failed', error);
                    return of(false);
                })
            );
        }
    }

    navigateToCurrentUrl() {
        // 
    }

    isTokenExpired(): boolean {
        const expiration = this.storageService.getItem(LocalStoreKey.expiredIn);
        if (!expiration) return true;
        return new Date(expiration) < new Date();
    }

    refreshToken(): Observable<any> {
        const refreshToken = this.storageService.getItem(LocalStoreKey.refreshToken);
        if (!refreshToken) {
            return throwError(() => new Error('No refresh token available'));
        }

        const data = {
            clientId: env.clientId,
            clientSecret: env.clientSecret,
            refreshToken: refreshToken
        };

        return this.api.post(ApiUrl.REFRESH_TOKEN, data).pipe(
            tap((response: any) => {
                if (response.isSuccess) {
                    const data = response.data;
                    this.storageService.setItem(LocalStoreKey.appToken, data.accessToken);
                    this.storageService.setItem(LocalStoreKey.refreshToken, data.refreshToken);
                    this.storageService.setItem(LocalStoreKey.expiredIn, data.expiration);
                   
                }
            }),
            catchError((error) => {
                console.error('Token refresh failed', error);
                this.logout();
                return throwError(() => error);
            })
        );
    }

    logout() {
        this.storageService.removeItem(LocalStoreKey.appToken);
        this.storageService.removeItem(LocalStoreKey.refreshToken);
        this.storageService.removeItem(LocalStoreKey.expiredIn);
        this.router.navigate(['/login']);
    }

    getValidToken(): Observable<string> {
        if (this.isTokenExpired()) {
            return this.refreshToken().pipe(
                map(() => this.storageService.getItem(LocalStoreKey.appToken) || '')
            );
        } else {
            return of(this.storageService.getItem(LocalStoreKey.appToken) || '');
        }
    }

    get isDefaultAccount(){
       const defaultUser =  this.storageService.getItem(LocalStoreKey.idNguoiDung) ==default_user;
       return defaultUser;
    }
}
