// crud.service.ts
import { iCrud } from '../network/icrud';
import { Injectable } from '@angular/core';
import { Api } from '../network/api';
import { Observable } from 'rxjs';
import { BaseResponse } from '../models/base_response.model';
import { ChangePassword } from 'src/app/modules/user-and-group/models/change-password';

export class iMeBaseService<T> implements iCrud<T> {
    constructor(private baseApi: Api<T>) {
    }
    

    getDataByPage(url: string, page: number, pageSize: number,searchStr: string='',searchKey: string='') {
        let _url = `${url}?pageNumber=${page}&pageSize=${pageSize}`;
        if(searchStr != ''){
            if(searchKey!=''){
            _url+=`&Search.${searchKey}=${searchStr}`;
            }else{
                //default search by Ten
                _url+=`&Search.Ten=${searchStr}`;
            }
        }
        return this.baseApi.get(_url);
    }

    remove(url: string) {
        return this.baseApi.delete(url);
    }

    getAll(url: string) {
        return this.baseApi.get(url);
    }

    create(url: string, item: T) {
        return this.baseApi.post(url, item);
    }

    update(url: string, item: T) {
        return this.baseApi.put(url, item);
    }

    getById(url: string) {
        return this.baseApi.get(url);
    }

    getDanhMuc(url: string) {
        return this.baseApi.get(url);
    }

    postInfo(url: string){
        return this.baseApi.post(url,null);
    }

    postChangePassword(url: string, data: ChangePassword) {
        return this.baseApi.post(url, data);
    }
}