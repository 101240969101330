export class PhanHe {
    id: string;
    idSite: string;
    ten: string;
    moTa: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(ph: any) {
        ph = ph || {};
        this.id = ph.id;
        this.idSite = ph.idSite;
        this.ten = ph.ten;
        this.moTa = ph.moTa;
        this.taoBoi = ph.taoBoi;
        this.taoLuc = ph.taoLuc;
        this.capNhatBoi = ph.capNhatBoi;
        this.capNhatLuc = ph.capNhatLuc;
    }
}
