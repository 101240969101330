export class Platform {
    id: string;
    ten: string;
    moTa: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(platform: any) {
        platform = platform || {};
        this.id = platform.id;
        this.ten = platform.ten;
        this.moTa = platform.moTa;
        this.taoBoi = platform.taoBoi;
        this.taoLuc = platform.taoLuc;
        this.capNhatBoi = platform.capNhatBoi;
        this.capNhatLuc = platform.capNhatLuc;
    }
}
