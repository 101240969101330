export class Function {
    id: string;
    idSite: string;
    idPhanHe: string;
    ma: string;
    ten: string;
    moTa: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(f: any) {
        f = f || {};
        this.id = f.id;
        this.idSite = f.idSite;
        this.ma = f.ma;
        this.idPhanHe = f.idPhanHe;
        this.ten = f.ten;
        this.moTa = f.moTa;
        this.taoBoi = f.taoBoi;
        this.taoLuc = f.taoLuc;
        this.capNhatBoi = f.capNhatBoi;
        this.capNhatLuc = f.capNhatLuc;
    }
}
