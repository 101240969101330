export class SMSTemplate {
    id: string;
    idSite: string;
    ten: string;
    moTa: string;
    noiDung: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(smsTemplate: any) {
        smsTemplate = smsTemplate || {};
        this.id = smsTemplate.id;
        this.idSite = smsTemplate.idSite;
        this.noiDung = smsTemplate.noiDung;
        this.ten = smsTemplate.ten;
        this.moTa = smsTemplate.moTa;
        this.taoBoi = smsTemplate.taoBoi;
        this.taoLuc = smsTemplate.taoLuc;
        this.capNhatBoi = smsTemplate.capNhatBoi;
        this.capNhatLuc = smsTemplate.capNhatLuc;
    }
}
