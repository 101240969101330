import { Injectable } from '@angular/core';
import { OrganizationType } from 'src/app/modules/manager/models/organization-type.model';
import { ApiUrl } from '../enums/api_key';
import { ErrorCode } from 'src/app/modules/manager/models/error-code.model';
import { Language } from 'src/app/modules/manager/models/language.model';
import { EmailTemplate } from 'src/app/modules/manager/models/email-template.model';
import { Organization } from 'src/app/modules/manager/models/organization.model';
import { SMSTemplate } from 'src/app/modules/manager/models/sms-template.model';
import { Account } from 'src/app/modules/user-and-group/models/account.model';
import { Configration } from 'src/app/modules/config/models/configuration.model';
import { Function } from 'src/app/modules/manager/models/function.model';
import { OtpType } from 'src/app/modules/manager/models/otp-type.model';
import { Otp } from 'src/app/modules/manager/models/otp.model';
import { PhanQuyen } from 'src/app/modules/manager/models/phan-quyen.model';
import { PhanHe } from 'src/app/modules/manager/models/phanHe.model';
import { Platform } from 'src/app/modules/manager/models/platform.model';
import { RoleFunction } from 'src/app/modules/manager/models/role-function.model';
import { RoleGroup } from 'src/app/modules/manager/models/role-group.model';
import { SiteClient } from 'src/app/modules/manager/models/site-client.model';
import { Site } from 'src/app/modules/manager/models/site.model';
import { UserLogin } from 'src/app/modules/manager/models/user-login.model';
import { IdentificationType } from 'src/app/modules/manager/models/identification-type.model';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { UserGroup } from 'src/app/modules/user-and-group/models/user-group.model';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private mainUrl: string = '';

  constructor(private authService: AuthService<any>) { }

  build<T>(instance: T) {
    if (instance instanceof OrganizationType) {
      this.mainUrl = ApiUrl.LOAI_TO_CHUC;
    } else if (instance instanceof ErrorCode) {
      this.mainUrl = ApiUrl.ERROR_CODE;
    } else if (instance instanceof Language) {
      this.mainUrl = ApiUrl.LANGUAGE;
    } else if (instance instanceof EmailTemplate) {
      this.mainUrl = ApiUrl.EMAIL_TEMPLATE;
    } else if (instance instanceof Organization) {
      this.mainUrl = ApiUrl.ORGANIZATION;
    } else if (instance instanceof SMSTemplate) {
      this.mainUrl = ApiUrl.SMS_TEMPLATE;
    } else if (instance instanceof Account) {
      this.mainUrl = ApiUrl.ACCOUNT;
    } else if (instance instanceof Configration) {
      this.mainUrl = ApiUrl.CONFIGURATION;
    } else if (instance instanceof Function) {
      this.mainUrl = ApiUrl.CHUC_NANG;
    } else if (instance instanceof OtpType) {
      this.mainUrl = ApiUrl.OTP_TYPE;
    } else if (instance instanceof Otp) {
      this.mainUrl = ApiUrl.OTP;
    } else if (instance instanceof PhanQuyen) {
      this.mainUrl = ApiUrl.PHAN_QUYEN;
    } else if (instance instanceof PhanHe) {
      this.mainUrl = ApiUrl.PHAN_HE;
    } else if (instance instanceof Platform) {
      this.mainUrl = ApiUrl.NEN_TANG;
    } else if (instance instanceof RoleFunction) {
      this.mainUrl = ApiUrl.PHAN_QUYEN_CHUC_NANG;
    } else if (instance instanceof RoleGroup) {
      this.mainUrl = ApiUrl.NHOM_QUYEN;
    } else if (instance instanceof SiteClient) {
      this.mainUrl = ApiUrl.SITE_CLIENT;
    } else if (instance instanceof Site) {
      this.mainUrl = ApiUrl.SITE;
    } else if (instance instanceof UserGroup) {
      this.mainUrl = ApiUrl.NHOM_NGUOI_DUNG;
    } else if (instance instanceof UserLogin) {
      this.mainUrl = ApiUrl.NguoiDungDangNhap;
    } else if (instance instanceof IdentificationType) {
      this.mainUrl = ApiUrl.LOAI_GIAY_TO;
    }

  }
  // Modify existing methods to use AuthService if needed
  public getBaseUrl(): string {
    return this.authService.isDefaultAccount ? ApiUrl.API_CLIENT : ApiUrl.API_MANAGER;
  }

  getDeleteUrl(): string {
    return `${this.getBaseUrl()}${this.mainUrl}/xoa`;
  }

  getGetUrl(): string {
    return `${this.getBaseUrl()}${this.mainUrl}/danh-sach`;
  }

  getPostUrl(): string {
    return `${this.getBaseUrl()}${this.mainUrl}/them-moi`;
  }

  getPutUrl(): string {
    return `${this.getBaseUrl()}${this.mainUrl}/cap-nhat`;
  }

  getDetailUrl(): string {
    return `${this.getBaseUrl()}${this.mainUrl}/chi-tiet`;
  }

  getDanhMucUrl(idDanhMuc: any, pageSize: any, pageNumber: any, isAll: any = true): string {
    let url_params = '';
    if (idDanhMuc) {
      url_params += `IdDanhMuc=${idDanhMuc}`
    }
    if (isAll) {
      url_params += `&IsAll=${isAll}`
    } else {
      if (pageSize) {
        url_params += `&PageSize=${pageSize}`
      }
      if (pageNumber) {
        url_params += `&PageNumber=${pageNumber}`
      }
    }
    return `${ApiUrl.API_CLIENT}danh-muc/danh-sach?` + url_params;
  }

  // todo: get account info
  getAccountInfoUrl(): string {
    return `${this.getBaseUrl()}nguoi-dung/thong-tin`;
  }

  getChangePasswordUrl(): string {
    return `${this.getBaseUrl()}nguoi-dung/doi-mat-khau`;
  }
}
