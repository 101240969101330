import { Language } from "./language.model";

export class ErrorCode {
    id: string;
    idNgonNgu: string;
    ma: string;
    noiDung: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    ngonNgu: Language;
    tenNgonNgu: string;
    constructor(errorCode: any) {
        errorCode = errorCode || {};
        this.id = errorCode.id;
        this.ma = errorCode.ma;
        this.idNgonNgu = errorCode.idNgonNgu;
        this.noiDung = errorCode.noiDung;
        this.taoBoi = errorCode.taoBoi;
        this.taoLuc = errorCode.taoLuc;
        this.capNhatBoi = errorCode.capNhatBoi;
        this.capNhatLuc = errorCode.capNhatLuc;
        this.ngonNgu = new Language(errorCode.ngonNgu);
        this.tenNgonNgu = this.ngonNgu.ten;
    }
}
