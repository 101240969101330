import { authGuard } from './core/guards/auth.guard';
import { noAuthGuard } from './core/guards/no-auth.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { NotFoundComponent } from './modules/shared/components/not-found/not-found.component';
import { InternalServerErrorComponent } from './modules/shared/components/internal-server-error/internal-server-error.component';
import { ConfirmForgotPasswordComponent } from './modules/auth/components/confirm-forgot-password/confirm-forgot-password.component';

export const appRoutes: VexRoutes = [
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'server-error',
    component: InternalServerErrorComponent
  },
  {
    path: 'xac-nhan-quen-mat-khau',
    component: ConfirmForgotPasswordComponent
  },
  {
    path: 'auth',
    // canActivate: [noAuthGuard],
    loadChildren: () =>
      import('./modules/auth/auth.module').then(
        (m) => m.AuthModule
      )
  },
  {
    path: '',
    canActivate: [authGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      }, 
      {
        path: '',
        loadComponent: () =>
          import(
            './modules/dashboards/dashboard-analytics/dashboard-analytics.component'
          ).then((m) => m.DashboardAnalyticsComponent)
      },
      {
        path: 'manager',
        loadChildren: () =>
          import(
            './modules/manager/manager.module'
          ).then((m) => m.ManagerModule)
      },
      {
        path: 'config',
        loadChildren: () =>
          import(
            './modules/config/config.module'
          ).then((m) => m.ConfigModule)
      },
      {
        path: 'roles',
        loadChildren: () =>
          import(
            './modules/roles/roles.module'
          ).then((m) => m.RolesModule)
      },
      {
        path: 'user-and-group',
        loadChildren: () =>
          import(
            './modules/user-and-group/user-and-group.module'
          ).then((m) => m.UserAndGroupModule)
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'not-found'
  },
];
