export class Site {
    id: string;
    idToChuc: string;
    siteUrl: string;
    adminUrl: string;
    dangHoatDong: boolean;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(site: any) {
        site = site || {};
        this.id = site.id;
        this.idToChuc = site.idToChuc;
        this.siteUrl = site.siteUrl;
        this.adminUrl = site.adminUrl;
        this.dangHoatDong = site.dangHoatDong;
        this.taoBoi = site.taoBoi;
        this.taoLuc = site.taoLuc;
        this.capNhatBoi = site.capNhatBoi;
        this.capNhatLuc = site.capNhatLuc;
    }
}
