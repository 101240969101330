import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'vex-not-found',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {

}
