export enum LocalStoreKey {
    'appToken' = 'app_token',
    'refreshToken'='refresh_token',
    'idNguoiDung' = 'id_nguoidung',
    'expiredIn' = 'expired_in',
    'idSite' = 'id_site',
    'rememberMe' = 'remember_me',
    'accountEmail' = 'account_email',
    'accountPassword' = 'account_password',
    'appleToken' = 'apple_token',
    'google_profile' = 'google_profile',
    'facebook_profile' = 'facebook_profile',
    'web_profile' = 'web_profile',
    'apple_profile' = 'apple_profile',
    'id_platform' = 'id_platform',
    'app_role' = 'app_role',
}