import { Platform } from "./platform.model";
import { Site } from "./site.model";

export class SiteClient {
    id: string;
    idSite: string;
    idNenTang: string;
    clientId: string;
    clientSecret: string;
    dangHoatDong: boolean;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string; 
    site:Site;
    nenTang:Platform;
    siteUrl: string;
    tenNenTang: string;
    tenTrangThai: string;
    constructor(obj: any) {
        obj = obj || {};
        this.id = obj.id;
        this.idSite = obj.idToChuc;
        this.idNenTang = obj.idNenTang;
        this.clientId = obj.clientId;
        this.clientSecret = obj.clientSecret;
        this.dangHoatDong = obj.dangHoatDong;
        this.taoBoi = obj.taoBoi;
        this.taoLuc = obj.taoLuc;
        this.capNhatBoi = obj.capNhatBoi;
        this.capNhatLuc = obj.capNhatLuc;
        this.site = obj.site;
        this.nenTang = obj.nenTang;
        this.siteUrl =this.site? this.site.siteUrl:'',
        this.tenNenTang = this.nenTang? this.nenTang.ten:'',
        this.tenTrangThai = this.dangHoatDong?'Đang hoạt động':'Đã dừng'
    }
}
