import { IdentificationType } from "./identification-type.model";
import { OrganizationType } from "./organization-type.model";

export class Organization {
    id: string;
    idLoaiToChuc: string;
    tenToChuc: string;
    tenVietTat: string;
    idLoaiGiayTo: string;
    soGiayTo: string;
    emailToChuc: string;
    soDienThoaiToChuc: string;
    diaChiToChuc: string;
    tenNguoiDaiDien: string;
    emailNguoiDaiDien: string;
    soDienThoaiNguoiDaiDien: string;
    tenNguoiLienHe: string;
    emailLienHe: string;
    soDienThoaiLienHe: string;
    batDau: Date;
    ketThuc: Date;
    loaiGiayTo: IdentificationType;
    loaiToChuc: OrganizationType;
    tenLoaiGiayTo: string;
    tenLoaiToChuc: string;
    constructor(obj: any) {
        obj = obj || {};
        this.id = obj.id;
        this.idLoaiToChuc = obj.idLoaiToChuc;
        this.tenToChuc = obj.tenToChuc;
        this.tenVietTat = obj.tenVietTat;
        this.idLoaiGiayTo = obj.idLoaiGiayTo;
        this.soGiayTo = obj.soGiayTo;
        this.emailToChuc = obj.emailToChuc;
        this.soDienThoaiToChuc = obj.soDienThoaiToChuc;
        this.diaChiToChuc = obj.diaChiToChuc;
        this.tenNguoiDaiDien = obj.tenNguoiDaiDien;
        this.emailNguoiDaiDien = obj.emailNguoiDaiDien;
        this.soDienThoaiNguoiDaiDien = obj.soDienThoaiNguoiDaiDien;
        this.tenNguoiLienHe = obj.tenNguoiLienHe;
        this.emailLienHe = obj.emailLienHe;
        this.soDienThoaiLienHe = obj.soDienThoaiLienHe;
        this.batDau = obj.batDau;
        this.ketThuc = obj.ketThuc;
        this.loaiGiayTo = obj.loaiGiayTo;
        this.loaiToChuc = obj.loaiToChuc;
        this.tenLoaiGiayTo = this.loaiGiayTo ? this.loaiGiayTo.ten : '';
        this.tenLoaiToChuc = this.loaiToChuc ? this.loaiToChuc.ten : '';
    }
}
