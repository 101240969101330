import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { getAccessToken } from '../commons/storage';
import { ApiUrl } from '../enums/api_key';
import { AppRoutes } from '../enums/app_routes';


export const authGuard = (snapshot: ActivatedRouteSnapshot) => {
  const router = inject(Router);

  const token = getAccessToken();

  if (token === '' || token === null || token === undefined) return router.navigate([AppRoutes.LOGIN]);

  let ok = false;

  if (token) {
    ok = true;
  } else {
    ok = false;
  }

  if (ok) {
    return true;
  } else {
    return router.navigate([ApiUrl.LOGIN]);
  }
};
