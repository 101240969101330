export class OrganizationType {
    id: string;
    ten: string;
    moTa: string;
    taoBoi: string;
    taoLuc: string;
    capNhatBoi: string;
    capNhatLuc: string;
    constructor(orgType: any) {
        orgType = orgType || {};
        this.id = orgType.id;
        this.ten = orgType.ten;
        this.moTa = orgType.moTa;
        this.taoBoi = orgType.taoBoi;
        this.taoLuc = orgType.taoLuc;
        this.capNhatBoi = orgType.capNhatBoi;
        this.capNhatLuc = orgType.capNhatLuc;
    }
}
