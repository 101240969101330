import { Injectable } from '@angular/core';
import { LocalStoreKey } from '../enums/localstore_key';
import { jwtDecode } from 'jwt-decode';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SocialPlatform } from 'src/app/modules/auth/enums/social-platform';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(
    private afAuth: AngularFireAuth,

  ) {
    //
  }
  setItem(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  getItem(key: string): any {
    const item = localStorage.getItem(key);
    return item ?? null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
  // Lưu một danh sách đối tượng vào Local Storage
  setList(key: string, list: any[]): void {
    localStorage.removeItem(key);
    localStorage.setItem(key, JSON.stringify(list));
  }

  // Lấy danh sách đối tượng từ Local Storage
  getList(key: string): any[] {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  }

  // Xóa 1 item trong list theo id
  removeItemByFileName(key: string, fileName: any) {
    const data = this.getList(key);
    if (data.length > 0) {
      const index = data.findIndex((x: any) => x === fileName);
      if (index > 0) {
        const rs = data.splice(index, 1);
        this.removeItem(key);
        this.setList(key, rs);
      }
    }
  }

  // Add 1 item
  addItem(key: string, item: never) {
    let data = [];
    data = this.getList(key);
    if (data.length > 0) {
      const index = data.findIndex((x: any) => x === item);
      if (index < 0) {
        data.push(item);
        this.removeItem(key);
        this.setList(key, data);
      }
    }
  }

  decodeToken(token: string) {
    return jwtDecode(token);
  }
  get hasToken(): boolean {
    return !!this.getItem(LocalStoreKey.appToken);
  }

  get profileAccount(): any {
    const idPlatform = this.getItem(LocalStoreKey.id_platform);
    if(idPlatform === SocialPlatform.google){
      return this.getList(LocalStoreKey.google_profile);
    }
    if(idPlatform === SocialPlatform.facebook){
      return this.getList(LocalStoreKey.facebook_profile);
    }
    if(idPlatform === SocialPlatform.apple){
      return this.getList(LocalStoreKey.apple_profile);
    }
    if(idPlatform === SocialPlatform.webadmin){
      return this.getList(LocalStoreKey.web_profile);
    }
    return null;
  }

  logout() {
    const idPlatform = this.getItem(LocalStoreKey.id_platform);
    this.afAuth.signOut();
    if(idPlatform === SocialPlatform.google){
      this.removeItem(LocalStoreKey.google_profile);
    }
    if(idPlatform === SocialPlatform.facebook){
      this.removeItem(LocalStoreKey.facebook_profile);
    }
    if(idPlatform === SocialPlatform.apple){
      this.removeItem(LocalStoreKey.apple_profile);
    }
    if(idPlatform === SocialPlatform.webadmin){
      this.removeItem(LocalStoreKey.web_profile);
    }
    this.removeItem(LocalStoreKey.id_platform);
  }

  get loginPlatform(): string {
    return this.getItem(LocalStoreKey.id_platform);
  }

  getAllKeys(): string[] {
    return Object.keys(localStorage);
  }
}
