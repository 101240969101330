<div class="flex-auto flex items-center container">
  <a
    class="action flex-none"
    color="primary"
    href="/"
    id="get-vex"
    mat-flat-button>
    <mat-icon
      class="icon-sm ltr:mr-2 rtl:ml-2"
      svgIcon="mat:info"></mat-icon>
    <span>Phiên bản 1.0.0</span>
  </a>
  <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
   IsMe - Hệ thống quản trị website
  </div>
</div>
