import { Injectable, Pipe } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getAccessToken } from '../commons/storage';
import { AppRoutes } from '../enums/app_routes';
import { BaseResponse } from '../models/base_response.model';
import { iCrud } from './icrud';


@Injectable({
  providedIn: 'root'
})
export class Api<T>{
  responseType = {
    blob: 'blob',
    arraybuffer: 'arraybuffer',
    json: 'json',
    text: 'text',
    none: ''
  };

  constructor(
    private http: HttpClient,
    private route: Router,
  ) { }
 

  get language() {
    return localStorage.getItem('lang') ?? '';
  }

  /**
   * Lấy thông tin token từ cookie
   * @returns Token: string
   */
  getToken() {
    return getAccessToken()?.replace(/\"/g, '');
  }

  /**
   * Get method
   * @param url
   */
  get(url: string, queryParams: any = null, contentType: string = 'application/json') {
    const header: any = {
      'Content-Type': contentType
    };
    if (queryParams) {
      const qrParams = new HttpParams();
      Object.keys(queryParams).forEach((key) => {
        qrParams.append(key, queryParams[key]);
      });
      header.params = qrParams;
    }
    return this.http.get<BaseResponse<T[]>>(environment.apiUrl + url, this.optionsRequest(header, false)).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  /**
   * POST download
   * @param url
   * @param data
   */
  postDownload(url: string, data: any) {
    data = JSON.stringify(data);
    return this.http
      .post(environment.apiUrl + url, data, this.getRequestOptionBlobNoContentType())
      .pipe(catchError(this.handleError));
  }
  /**
   * GET download
   * @param url
   * @param data
   */
  getDownload(url: string) {
    console.log('environment.apiUrl', environment.apiUrl);
    return this.http
      .get(environment.apiUrl + url, this.getRequestOptionBlobNoContentType())
      .pipe(catchError(this.handleError));
  }

  /**
   * Make request option no content-type for Blob
   */
  getRequestOptionBlobNoContentType() {
    const token = this.getToken();

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ` + token,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        'Accept-Language': this.language
        // ...this.getAdditionalHeaderData()
      }),
      responseType: 'blob' as const
    };

    return httpOptions;
  }

  /**
   * Post method
   * @param url
   * @param data
   */
  post(url: string, data: any) {
    const header = {
      'Content-Type': 'application/json'
    };
    data = JSON.stringify(data);
    return this.http.post<BaseResponse<T>>(environment.apiUrl + url, data, this.optionsRequest(header, false)).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  /**
   * POST multipath
   * @param url
   * @param data
   */
  postMultiPart(url: string, data: FormData) {
    return this.http
      .post(environment.apiUrl + url, data, this.optionsRequest({}, false, 'blob'))
      .pipe(catchError(this.handleError));
  }

  /**
   * POST multipath
   * @param url
   * @param data
   */
  postMultiPartJSON(url: string, data: FormData) {
    return this.http.post(environment.apiUrl + url, data, this.optionsRequest()).pipe(catchError(this.handleError));
  }

  /**
   * Put method
   * @param url
   * @param data
   */
  put(url: string, data: any) {
    const header = {
      'Content-Type': 'application/json'
    };
    data = JSON.stringify(data);
    return this.http.put<BaseResponse<T>>(environment.apiUrl + url, data, this.optionsRequest(header, false)).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  // định nghĩa lại put với header chứa mime-type là image/jpeg
  putImage(url: string, data: string) {
    const header = {
      'Content-Type': 'application/json',
      'mime-type': 'image/jpeg'
    };
    data = JSON.stringify(data);
    return this.http
      .put(environment.apiUrl + url, data, this.optionsRequest(header, false))
      .pipe(catchError(this.handleError));
  }

  /**
   * PUT multipart
   * @param url
   * @param data
   */
  putMultiPart(url: string, data: FormData) {
    return this.http.put<BaseResponse<T>>(environment.apiUrl + url, data, this.optionsRequest()).pipe(catchError(this.handleError));
  }

  /**
   * Delete method
   * @param url
   */
  delete(url: string, param = '') {
    const token = this.getToken();

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
        'Accept-Language': this.language
      }),
      body: JSON.stringify(param)
    };

    return this.http.delete<BaseResponse<T>>(environment.apiUrl + url, options).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deleteFormData(url: string, param = '') {
    const token = this.getToken();

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
        'Accept-Language': this.language
      }),
      body: param
    };

    return this.http.delete(environment.apiUrl + url, options).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  optionsRequest(
    headers: any = {},
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text' | '',
    body: any = null
  ) {
    const token = this.getToken();
    const header = {
      Authorization: token ? `Bearer ` + token : ``,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Accept-Language': this.language,
      ...headers
    };
    const options: any = { headers: new HttpHeaders(header) };

    if (reportProgress == true) {
      options['reportProgress'] = true;
    }

    if (responseType != '' && responseType != null && responseType != undefined) {
      options['responseType'] = responseType;
    }

    if (body != null) {
      options['body'] = JSON.stringify(body);
    }

    return options;
  }

  /**
   * Handle Error
   * @param error
   * @returns
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error?.status}, ` + `body was: ${error?.error}`);
    }

    if (error?.error?.message == 'ECT-00001110') {
      localStorage.clear();
      this?.route?.navigate(['/auth/signin']);
    }

    // return an observable with a user-facing error message
    return throwError(() => error);
    // 'Something bad happened; please try again later.');
  }

  /**
   * Download file
   * @param url
   */
  download(url: string) {
    return this.http
      .get(environment.apiUrl + url, this.optionsRequest({}, false, 'blob'))
      .pipe(catchError(this.handleError));
  }
  /**
   * Download file async
   * @param url
   */
  downloadAsync(url: string): Observable<any> {
    return this.http.get(environment.apiUrl + url, this.optionsRequest({}, false, 'blob'));
  }

  /**
   * Get method
   * @param url
   */
  customGet(url: string, data: any = null) {
    const header: any = {
      'Content-Type': 'application/json',
      Authorization: data.token ? `Bearer ` + data.token : ``
    };
    return this.http
      .get(environment.apiUrl + url, this.customOptionsRequest(header, false))
      .pipe(catchError(this.handleError));
  }

  customOptionsRequest(
    headers: any = {},
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text' | '',
    body: any = null
  ) {
    const header = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Accept-Language': this.language,
      ...headers
    };
    const options: any = { headers: new HttpHeaders(header) };

    if (reportProgress == true) {
      options['reportProgress'] = true;
    }

    if (responseType != '' && responseType != null && responseType != undefined) {
      options['responseType'] = responseType;
    }

    if (body != null) {
      options['body'] = JSON.stringify(body);
    }
    return options;
  } 
}
